// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_03-module--afterCaption--5f3c8";
export var colWrapper = "maine_digest_2022_03-module--colWrapper--14587";
export var columnWrapper = "maine_digest_2022_03-module--columnWrapper--bfb8c";
export var dateline = "maine_digest_2022_03-module--dateline--a7185";
export var heading = "maine_digest_2022_03-module--heading--7245c";
export var headingLogo = "maine_digest_2022_03-module--headingLogo--694a0";
export var headingWrapper = "maine_digest_2022_03-module--headingWrapper--6fdf2";
export var heroImage = "maine_digest_2022_03-module--heroImage--8ceb7";
export var instance = "maine_digest_2022_03-module--instance--c6506";
export var latestnewsarticleheadline = "maine_digest_2022_03-module--latestnewsarticleheadline--f29cc";
export var leftCol = "maine_digest_2022_03-module--leftCol--00dee";
export var maineDigest = "maine_digest_2022_03-module--maineDigest--ce075";
export var photoCaption = "maine_digest_2022_03-module--photoCaption--59e59";
export var rightCol = "maine_digest_2022_03-module--rightCol--ae30a";
export var subheading = "maine_digest_2022_03-module--subheading--eb524";
export var textWrapper = "maine_digest_2022_03-module--textWrapper--6c527";
export var whatsNew = "maine_digest_2022_03-module--whatsNew--8fb0c";